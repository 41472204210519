import { parseNumericId } from '../numeric-id';

interface SpecialFachgebieteOption {
  name: string;
  uid: number;
  root: number;
}

/**
 * Shows all elements inside the given list that match the filter
 * and hide the ones that do not. Reports, if any child matched
 * the filters.
 */
export const filterPersonDomElements = (
  listRootElement: Element,
  selectedLocation: string | number = 0,
  selectedDepartment: string | number = 0,
  selectedName: string = '',
  selectedSpecialFachgebiete: SpecialFachgebieteOption[] = []
) => {
  const location = parseNumericId(selectedLocation);
  const department = parseNumericId(selectedDepartment);
  const name = selectedName.toLocaleLowerCase();
  const specialDepartments = new Set(
    selectedSpecialFachgebiete.map((option) => option.uid)
  );

  let filtered = [
    ...listRootElement.querySelectorAll<HTMLElement>('.doctor__col'),
  ];

  const applyFilter = (block: (element: HTMLElement) => boolean) => {
    filtered = filtered.filter((element) => {
      const blockFilterResult = block(element);
      if (!blockFilterResult) {
        element.hidden = true;
      }
      return blockFilterResult;
    });
  };

  if (location > 0) {
    applyFilter(
      (locationElement) =>
        locationElement.dataset['standorte']
          ?.split(',')
          .map((id) => parseNumericId(id))
          .includes(location) === true
    );
  }

  if (department > 0 || specialDepartments.size !== 0) {
    applyFilter((locationElement) => {
      const departmentsOfItem =
        locationElement.dataset['fachgebiete']
          ?.split(',')
          .map((id) => parseNumericId(id)) ?? [];

      if (department > 0 && !departmentsOfItem.includes(department)) {
        return false;
      }

      if (specialDepartments.size === 0) {
        return true;
      }

      return departmentsOfItem.some((itemDepartment) =>
        specialDepartments.has(itemDepartment)
      );
    });
  }

  if (name !== '') {
    applyFilter(
      (locationElement) =>
        locationElement.dataset['search']
          ?.toLocaleLowerCase()
          .includes(name) === true
    );
  }

  filtered.forEach((e) => (e.hidden = false));

  return filtered.length !== 0;
};
