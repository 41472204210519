/**
 * Breakpoints config for smartphones (portrait and landscape)
 */
export const standortBreakpointsConfig = [
  /**
   * Portrait
   */
  {
    0: {
      slidesPerView: 1.2,
    },
    767: {
      slidesPerView: 2.4,
    },
    1023: {
      slidesPerView: 3,
    },
  },
  /**
   * Landscape
   */
  {
    0: {
      slidesPerView: 2.4,
    },
    767: {
      slidesPerView: 3,
    },
  }
]
