import $ from "jquery";
import {hoverIntent} from "./hover-intent/hover-intent";

export default function InitHeader() {

  /**
   *  Open Mobile Menu if clicked on the burger icon
   */
  $(".menu__button").on("click", (e) => {
    const $root = $("html");
    $root.toggleClass("header-open");
    $(e.currentTarget).toggleClass("menu__button--open");
    $(".hidden-desktop .header__menu").toggleClass("header__menu--open");

    if ($root.hasClass("header-open")) {
      jQuery(".mega-menu").each(function () {
        jQuery(this).removeClass("mega-menu--open");
      });

      toggleSearchContainer(true);
    } else {
      handleNavigationClosure();
    }
  });

  /**
   * Activate the background overlay if hovering over mobile menu level 1
   * Hide header search if any mega menu / submenu is activated, and show it back after closing
   */
  const menuItems = document.querySelectorAll(".item--level-1.item--has-megamenu-children, .item--level-1.item--has-submenu-children");

  for (const menuItem of menuItems) {
    hoverIntent(
      menuItem,
    () => {
        jQuery(menuItem).addClass("hover-intent");
        jQuery("body").addClass("body body--menu-open");
        const $searchContainer = jQuery(".header__search-container");
        if ($searchContainer.hasClass("header__search-container--active")) {
          $searchContainer.hide();
        }
      },
      () => {
        jQuery(menuItem).removeClass("hover-intent");
        jQuery("body").removeClass("body body--menu-open");
        const $searchContainer = jQuery(".header__search-container");
        if ($searchContainer.hasClass("header__search-container--active")) {
          $searchContainer.show();
        }
      },
      {
        timeout: 200
      },
    );
  }

  /**
   * Close the mobile menu on window resize, if the desktop view is already visible
   */
  jQuery(window).on("resize", function () {
    const $menuButton = jQuery(".menu__button");
    if (!$menuButton.is(":visible")) {
      handleNavigationClosure();
    }
  });

  /**
   * Open the next Mobile Menu Level, when there's a click on the parent.
   */
  jQuery(".item__link__wrapper").on("click", function (e) {
    if (e.target.tagName === "A") return;
    let item = jQuery(this).closest(".item");
    if (item.hasClass("item--has-children")) {
      item.toggleClass("item--is--open");
    }
  });

  /**
   * Add the body node the scroll state to force header changes (eg. update of the logo size)
   */
  jQuery(window).scroll(function () {
    let $body = jQuery("body");

    if (jQuery(window).scrollTop() !== 0 && jQuery(window).scrollTop() > 85) {
      if (!$body.hasClass("scroll")) {
        // the scrollbar cannot dissapear after the header turns smaller
        if (jQuery(window).scrollTop() - 95 > 0) {
          $body.addClass("scroll");
        }
      }
    } else {
      $body.removeClass("scroll");
    }
  });

  /**
   * Header search Toggle
   */
  jQuery(".header__search-toggle").on("click", function () {
    toggleSearchContainer();
    handleNavigationClosure();
  });

  /**
   * Search input event
   */
  jQuery(".header__search-input")
    .on("click input", function (e) {
      /**
       * Toggle submit button active state on input
       */
      const $submitButton = jQuery(this).closest(".header__search").find(".header__search-button");
      if (jQuery(this).val()) {
        if (!$submitButton.hasClass("header__search-button--active")) {
          $submitButton.addClass("header__search-button--active");
        }
      } else {
        $submitButton.removeClass("header__search-button--active");
      }
    })
    .on("keydown", function (e) {
      /**
       * Right arrow key press autocompletes the search input
       */
      if (e.which === 39) {
        autoCompleteSearch();
      }
    });

  /**
   * Search hint field handling
   * Whenever the Solr suggestion field gets manipulated (shown, updated)
   * The hint field of the header search gets updated with the first suggestion loaded
   */
  const observerConfig = {childList: true, attributes: true, subtree: true};

  jQuery(".autocomplete-suggestions").each(function () {
    const target = this;
    const suggestionContainerObserver = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {

        const $hintContainer = jQuery(".header__search:visible .header__hint");
        const $hintContainerCE = jQuery(".search__hint");

        /**
         * If any Solr Suggestions occur, place the first suggestion in the hint field
         */
        if (mutation.attributeName === "style" || mutation.addedNodes.length || mutation.removedNodes.length) {
          const $solrSuggestionContainer = jQuery(mutation.target);

          const isSuggestionContainerVisible = ($solrSuggestionContainer.css("display") === "block");

          const inputValue = jQuery(".header__search:visible .header__search-input").val();
          const inputValueCE = jQuery(".search__input").val();

          const hintPhrase = $solrSuggestionContainer.find(".autocomplete-suggestion").first().text();

          const hintPhraseToInsert = hintPhrase.substring(inputValue.length);
          const hintPhraseToInsertCE = hintPhrase.substring(inputValueCE.length);

          if (!!hintPhraseToInsert && isSuggestionContainerVisible && inputValue) {
            $hintContainer.html("<div class=\"header__hint-hidden\">" + inputValue + "</div>" + hintPhraseToInsert);
          } else {
            $hintContainer.html("");
          }

          if (!!hintPhraseToInsertCE && isSuggestionContainerVisible && inputValueCE && jQuery(".search__input").is(":focus")) {
            $solrSuggestionContainer.addClass("is-from-search-ce");
            $hintContainerCE.html("<div class=\"search__hint-hidden\">" + inputValueCE + "</div>" + hintPhraseToInsertCE);
          } else {
            $hintContainerCE.html("");
          }
        }

        if (mutation.attributeName === "class" && mutation.target.classList.contains("autocomplete-selected")) {
          /**
           * If any Solr Suggestion gets selected (through arrow navigating), clear the hint field
           */
          if (!jQuery(mutation.target).is(":hover")) {
            $hintContainer.html("");
          }
        }
      });
    });
    suggestionContainerObserver.observe(target, observerConfig);
  });

  /**
   * Toggles the search container
   * @param searchClose determines whether an action should be fired which closes the
   * search container
   */
  const toggleSearchContainer = (searchClose = false) => {
    const $visibleSearchToggle = jQuery(".header__search-toggle:visible");
    const $searchContainer = $visibleSearchToggle
      .closest(".header__search")
      .find(".header__search-container").first();
    const $inputSearchField = $visibleSearchToggle
      .closest(".header__search").find(".header__search-input").first();

    /**
     * Remove any search inputs from previous searches
     */
    $inputSearchField.val("");

    /**
     * Search container toggle
     */
    if (searchClose) {
      $searchContainer.removeClass("header__search-container--active");
      $visibleSearchToggle.removeClass("header__search-toggle--active");
    } else {
      $searchContainer.toggleClass("header__search-container--active");
      $visibleSearchToggle.toggleClass("header__search-toggle--active");
    }

    /**
     * Set input field on focus / focusout
     */
    if ($searchContainer.hasClass("header__search-container--active")) {
      $inputSearchField.focus();
    } else {
      $inputSearchField.focusout();
    }
  };

  /**
   * Handles any dynamically added header functions to simulate mobile menu closure
   * Needed for resize (switch between desktop and mobile), and for mobile menu closer
   */
  const handleNavigationClosure = () => {
    const $root = jQuery("html"), $menuButton = jQuery(".menu__button"), $headerMenu = jQuery(".header__menu"),
      $megaMenu = jQuery(".mega-menu"), $subMenu = jQuery(".column__subitems"),
      $megaMenuItems = jQuery(".item--has-megamenu-children"), $subMenuItems = jQuery(".mega-menu__item--has-subitems");

    $root.removeClass("header-open");
    $headerMenu.removeClass("header__menu--open");
    $menuButton.removeClass("menu__button--open");
    $megaMenu.each(function () {
      jQuery(this).removeClass("mega-menu--open");
    });
    $subMenu.each(function () {
      jQuery(this).removeClass("column__subitems--open");
    });
    $megaMenuItems.each(function () {
      jQuery(this).removeClass("item--has-open-megamenu-children");
    });
    $subMenuItems.each(function () {
      jQuery(this).removeClass("mega-menu__item--has-open-subitems");
    });
  };

  /**
   * Inserts the type-ahead hint and clears the hint container
   */
  const autoCompleteSearch = () => {
    const $visibleHeaderSearch = jQuery(".header__search:visible");

    const $headerHintContainer = $visibleHeaderSearch.find(".header__hint");
    const $headerSearchInput = $visibleHeaderSearch.find(".header__search-input");

    const inputValue = $headerSearchInput.val();
    const hintPhrase = $headerHintContainer.text();

    if (!!hintPhrase) {
      $headerSearchInput.val(inputValue + hintPhrase.substring(inputValue.length));
      $headerHintContainer.html("");
    }
  };
}
