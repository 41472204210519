/**
 * Initializes the infinite scroll functionality for a person list.
 *
 * This functionality changes the visibility of all list elements
 * to hidden after the browser has rendered them.
 * Then on user scroll, elements are slowly made visible again.
 */
export const createInfiniteScrollHandler = (listRoot: Element) => {
  if (!listRoot.querySelector('.infinity-list')) {
    return;
  }

  const trigger = listRoot.querySelector('.infinity-list__trigger');

  if (!trigger) {
    return;
  }

  const partiallyVisibleList = createPartiallyVisibleList(listRoot);

  const destroyListUnveilObserver =
    unveilListItemsWhileTriggerElementIsIntersectingViewPort(
      partiallyVisibleList,
      trigger
    );

  return {
    destroy() {
      destroyListUnveilObserver();
      for (const _ of partiallyVisibleList) {
      }
      trigger.remove();
    },
  };
};

/**
 * Registers an observer that will unveil list items gracefully
 * while the view port is intersecting the trigger element.
 *
 * @return a callback used to disconnect / destroy the underlying observer.
 * This will not remove the trigger element.
 */
const unveilListItemsWhileTriggerElementIsIntersectingViewPort = (
  list: ReturnType<typeof createPartiallyVisibleList>,
  trigger: Element
) => {
  const itemUnveilRunner = createAnimationFrameRunner(() => {
    const itemsToLoad = getAmountOfItemsToLoad();
    for (let i = 0; i < itemsToLoad; i++) {
      list.next();
    }
  });

  const observer = new IntersectionObserver((entries) => {
    if (entries[0]?.isIntersecting) {
      itemUnveilRunner.start();
    } else {
      itemUnveilRunner.stop();
    }
  });

  observer.observe(trigger);

  return () => {
    observer.disconnect();
  };
};

/**
 * An animation frame runner runs the given task once on every animation frame,
 * after it has been started by invoking the start method.
 *
 * Invoking stop stops the task from running in the future,
 * until start is invoked again.
 */
const createAnimationFrameRunner = (task: () => void) => {
  let shouldContinueRunning = false;

  const runTaskContinuouslyOnAnimationFrames = () => {
    if (!shouldContinueRunning) {
      return;
    }
    task();
    requestAnimationFrame(() => {
      runTaskContinuouslyOnAnimationFrames();
    });
  };

  return {
    start: () => {
      shouldContinueRunning = true;
      runTaskContinuouslyOnAnimationFrames();
    },
    stop: () => {
      shouldContinueRunning = false;
    },
  };
};

const getAmountOfItemsToLoad = () => {
  const documentWidth = document.body.clientWidth;
  return documentWidth < 1440 ? (documentWidth < 768 ? 2 : 4) : 9;
};

const createPartiallyVisibleList = (listRoot: Element) => {
  const elements = listRoot.querySelectorAll<HTMLElement>(
    '.infinity-list__element'
  );

  elements.forEach((element) =>
    element.classList.add('infinity-list__element--not-yet-loaded')
  );

  let veilIndex = 0;

  return {
    next() {
      if (veilIndex === elements.length) {
        return { done: true, value: null };
      }

      elements
        .item(veilIndex)
        .classList.remove('infinity-list__element--not-yet-loaded');
      veilIndex++;

      return { done: false, value: null };
    },
    [Symbol.iterator]() {
      return this;
    },
  };
};
