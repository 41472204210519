import jQuery from 'jquery';

export default function InitVideo() {
  jQuery(document).on('click', '.video', function () {
    if (!jQuery(this).hasClass('video--is-playing')) {
      jQuery(this).addClass('video--is-playing');
      let videoHtml = jQuery(this).data('video');
      jQuery(this).find('.video__container').append(videoHtml);
    }
  });
}
