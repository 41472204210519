import $ from 'jquery'
import 'jquery-ui/ui/widget'
import 'jquery-ui/ui/widgets/menu'
import 'jquery-ui/ui/form-reset-mixin'
import 'jquery-ui/ui/keycode'
import 'jquery-ui/ui/labels'
import 'jquery-ui/ui/position'
import 'jquery-ui/ui/unique-id'
import 'jquery-ui/ui/version'
import 'jquery-ui/ui/widgets/selectmenu'

function updateRelatedDropdown(relatedDropdown, visibleOptions) {
  let optionsArray = $(relatedDropdown).find('option').get()

  optionsArray.forEach((option) => {
    // type of values in visibleOptions[] is int, but in dropdown string, so for comparison parse option to int
    const optionValue = parseInt($(option).val())
    if (!!visibleOptions && visibleOptions.includes(optionValue)) {
      $(option).removeClass('hidden')
      $(option).attr('disabled', false)
    } else {
      $(option).addClass('hidden')
      $(option).attr('disabled', true)
    }
  })

  $(relatedDropdown).selectmenu().selectmenu('refresh', true)
}

export default function ConditionalDropdowns(conditionalDropdown) {
  const conditionsJSON = $(conditionalDropdown).data('dropdown-conditions')
  const select1 = $(conditionalDropdown).find('.dropdown--first-condition select')
  const select2 = $(conditionalDropdown).find('.dropdown--second-condition select')

  select1.on('selectmenuchange', () => {
    updateRelatedDropdown(select2, conditionsJSON.dropdown1[$(select1).val()])
  })

  select2.on('selectmenuchange', () => {
    updateRelatedDropdown(select1, conditionsJSON.dropdown2[$(select2).val()])
  })
}
