const collapsibleRootClassName = 'collapsible'

const collapsibleToggleClassName = 'collapsible__toggle'

const collapsibleContentClassName = 'collapsible__content'
const collapsibleContentHiddenClassName = `${collapsibleContentClassName}--hidden`

const collapsibleChevronClassName = 'collapsible__chevron'
const collapsibleChevronOpenClassName = `${collapsibleChevronClassName}--open`

const collapsibleLabelClassName = 'collapsible__label'
const collapsibleLabelOpenClassName = `${collapsibleLabelClassName}--open`

/**
 * @param {HTMLElement|HTMLDetailsElement} rootElement
 */
function showCollapsible(rootElement) {
  rootElement.querySelector('.' + collapsibleContentClassName).classList.remove(collapsibleContentHiddenClassName)
  rootElement.querySelector('.' + collapsibleChevronClassName).classList.add(collapsibleChevronOpenClassName)
  rootElement.querySelector('.' + collapsibleLabelClassName).classList.add(collapsibleLabelOpenClassName)

  if (typeof rootElement.open === 'boolean') {
    rootElement.open = true
  }
}

/**
 * @param {HTMLElement|HTMLDetailsElement} rootElement
 */
function hideCollapsible(rootElement) {
  rootElement.querySelector('.' + collapsibleContentClassName).classList.add(collapsibleContentHiddenClassName)
  rootElement.querySelector('.' + collapsibleChevronClassName).classList.remove(collapsibleChevronOpenClassName)
  rootElement.querySelector('.' + collapsibleLabelClassName).classList.remove(collapsibleLabelOpenClassName)

  if (typeof rootElement.open === 'boolean') {
    rootElement.open = false
  }
}

/**
 * @param {HTMLElement|HTMLDetailsElement} rootElement
 * @return boolean
 */
function isCollapsibleOpen(rootElement) {
  if (!rootElement.classList.contains(collapsibleRootClassName)) {
    throw new Error('Provided element is not a collapsible')
  }

  if (typeof rootElement.open === 'boolean') {
    return rootElement.open === true
  }

  return !rootElement.querySelector('.' + collapsibleContentClassName).classList.contains(collapsibleContentHiddenClassName)
}

export default function InitCollapsible() {

  for (let linkElement of document.querySelectorAll('a')) {
    const href = linkElement.href
    if (typeof href !== 'string' || href.length === 0) {
      continue
    }

    const hash = linkElement.hash
    if (hash.length <= 1 || hash[1] !== 'c') {
      continue
    }

    // The link only changes the fragment of the url.
    // But if the current location hash is the same as the one of the target,
    // the browser will jump to the element,
    // but it won't dispatch a HashChangeEvent.
    // So we dispatch a HashChangeEvent
    linkElement.addEventListener('click', () => {
      if (linkElement.href === window.location.href) {
        const hashChangeEvent = new HashChangeEvent('hashchange', {
          newURL: window.location.href,
          oldURL: window.location.href
        })
        window.dispatchEvent(hashChangeEvent)
      }
    })
  }

  for (let toggleElement of document.getElementsByClassName(collapsibleToggleClassName)) {
    toggleElement.addEventListener('click', (event) => {
      event.preventDefault()
      /**
       * @type {HTMLDivElement}
       */
      const currentToggleElement = event.target
      let collapsibleRoot = currentToggleElement.parentElement
      if (collapsibleRoot === null) {
        console.warn('Could not get parent of collapsible__toggle', event)
        return
      }

      if (collapsibleRoot.classList.contains(collapsibleToggleClassName)) {
        collapsibleRoot = collapsibleRoot.parentElement
      }

      if (!collapsibleRoot.classList.contains(collapsibleRootClassName)) {
        console.warn('Parent of collapsible__toggle was not a collapsible')
        return;
      }

      if (!isCollapsibleOpen(collapsibleRoot)) {
        showCollapsible(collapsibleRoot)
      } else {
        hideCollapsible(collapsibleRoot)
      }
    })
  }

  /**
   * @param {string} hashContent
   */
  function openFromHashContent(hashContent) {
    if (hashContent.length === 0) {
      return;
    }
    const element = document.getElementById(hashContent)
    if (element === null) {
      return;
    }
    if (!element.classList.contains(collapsibleRootClassName)) {
      return;
    }
    showCollapsible(element)
  }

  /**
   * @param {HashChangeEvent} event
   */
  function onHashChange(event) {
    const url = new URL(event.newURL)

    if (url.hash.length <= 1) {
      return
    }

    openFromHashContent(url.hash.substr(1))
  }

  // register hash change listener for auto open of collapsible
  window.addEventListener('hashchange', onHashChange)

  openFromHashContent(window.location.hash.substr(1) || '')
}
