import jQuery from 'jquery';

export default function InitSearch() {
  const searchBlock = jQuery('.block--solr_pi_search')
  searchBlock.find('header > .headline');
  jQuery('.search__input')
    .on('keydown', function (e) {
      /**
       * Right arrow key press autocompletes the search input
       */
      if (e.which === 39) {
        autoCompleteSearchCe()
      }
    })

  /**
   * Inserts the type-ahead hint and clears the hint container
   */
  const autoCompleteSearchCe = () => {
    const $searchContainer = jQuery('.block--solr_pi_search')
    const $searchHintContainer = $searchContainer.find('.search__hint')
    const $searchInput = $searchContainer.find('.search__input')

    const inputValue = $searchInput.val()
    const hintPhrase = $searchHintContainer.text()

    if (!!hintPhrase) {
      $searchInput.val(inputValue + hintPhrase.substring(inputValue.length))
      $searchHintContainer.html('')
    }
  }


}
