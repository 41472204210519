import jQuery from 'jquery';

export default function InitTeaser() {

  jQuery('.card--link').each(function () {
    if (jQuery(this).find('.link').length > 0) {
      jQuery(this).on('click', function () {
        if (jQuery(this).find('.link').eq(0).attr('target') == 'blank')
          window.open(jQuery(this).find('.link').eq(0).attr('href'))
        else
          jQuery(location).attr('href', jQuery(this).find('.link').eq(0).attr('href'))
      })
    }
  })

  jQuery('.content-clicker').each(function () {
    let currentIndex = 0
    let pagination = jQuery(this).find('.content-clicker__pagination')
    let contentBox = jQuery(this).find('.content-clicker__content')
    let contentBoxNumber = jQuery(this).find('.content-clicker__teaser').length

    jQuery(this).find('.content-clicker__link').click(function () {
      let newIndex = jQuery(this).index()
      if (currentIndex !== newIndex) {
        currentIndex = switchActiveContent(jQuery(this), contentBox, pagination, currentIndex, newIndex)
      }
    })

    jQuery(this).find('.content-clicker__toggle').click(function () {
      let newIndex = 0

      jQuery(this).hasClass('content-clicker__toggle--down')
        ? newIndex = mod((currentIndex + 1), contentBoxNumber)
        : newIndex = mod((currentIndex - 1), contentBoxNumber)

      if (currentIndex !== newIndex) {
        currentIndex = switchActiveContent(jQuery(`.content-clicker__link:eq(${newIndex})`), contentBox, pagination, currentIndex, newIndex)
      }
    })
  })
}

function switchActiveContent($newCurrentLink, $contentBox, $pagination, currentIndex, newIndex) {
  $pagination.find('.content-clicker__link--active').removeClass('content-clicker__link--active')
  $newCurrentLink.addClass('content-clicker__link--active')

  $contentBox.find('.content-clicker__teaser').eq(currentIndex).fadeOut(50, function () {
    $contentBox.find('.content-clicker__teaser').eq(newIndex).fadeIn(50)
  })

  return newIndex
}

/**
 * JS has problems with modulo calculating if confronted with the negative values, e.g.
 * -1 % 3 = -1 (WRONG)
 * 7 % 4 = 3 (CORRECT)
 * The following function solves this problem
 * @param number Number to be calculated
 * @param modulo Modulo value
 * @returns {number}
 */
function mod(number, modulo) {
  return ((number % modulo) + modulo) % modulo
}
