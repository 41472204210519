import GLightbox from 'glightbox'
import jQuery from 'jquery';
import Cookies from 'js-cookie'

function getUrlParameter(location, name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export default function InitPushTeaser() {
  const lightbox = GLightbox({
    touchNavigation: false,
    draggable: false,
    closeOnOutsideClick: false,
    closeButton: false,
  })

  const pallasPushId = getUrlParameter(window.location.href, "pallasPushId")
  const pallasCookieName = 'pallasTeasers'

  let pushTeaser

  const allPushTeasers = document.getElementsByClassName('push-teaser')
  for (let i = 0; i < allPushTeasers.length; i++) {
    if (allPushTeasers[i].dataset.pushTeaserCampaignId === pallasPushId) {
      pushTeaser = allPushTeasers[i]
    }
  }

  if (!!pushTeaser) {
    const pushTeaserId = pushTeaser.dataset.pushTeaserId
    const pushTeaserCampaignId = pushTeaser.dataset.pushTeaserCampaignId

    const cookie = Cookies.get(pallasCookieName)
    let pushTeaserIds = cookie ? JSON.parse(cookie) : null
    // weird behav.: when you click a form anchor link the popup opens again
    let wasOpen = false

    // set pushteaserid or update id array when cookie exists
    const setCookie = (hasPallasCookie) => {
      if (hasPallasCookie) {
        let ids = pushTeaserIds
        ids.push(pushTeaserId)
        Cookies.set(pallasCookieName, JSON.stringify(ids))
      } else {
        Cookies.set(pallasCookieName, JSON.stringify([pushTeaserId]))
      }

    }

    jQuery('.teaser__close, a').on('click', function () {
      lightbox.close()
      if (!!pushTeaserIds) {
        if (!pushTeaserIds.includes(pushTeaserId)) {
          setCookie(true)
        }
      } else {
        setCookie(false)
      }
    });

    lightbox.insertSlide({
      content: pushTeaser,
    }, 0);

    // if 'pallasTeasers' cookie exists
    if (!wasOpen) {
      if (!!pushTeaserIds) {
        if (!pushTeaserIds.includes(pushTeaserId)) {
          if (!!pallasPushId) {
            if (pushTeaserCampaignId === pallasPushId) {
              lightbox.open()
              wasOpen = true
            }
          }
        }
      } else {
        if (pushTeaserCampaignId === pallasPushId) {
          lightbox.open()
          wasOpen = true
        }
      }
    }
  }
}
