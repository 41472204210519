import jQuery from 'jquery';

export default function InitJobs() {
  let myEventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
  let myEventListener = window[myEventMethod];
  let myEventMessage = myEventMethod == "attachEvent" ? "onmessage" : "message";
  let iframe = jQuery('.jobs iframe');

  if (iframe !== undefined) {
    iframe.attr('name', document.location.href);
    myEventListener(myEventMessage, function (e) {
      if (typeof e.data === 'string' || e.data instanceof String) {
        let h = Number(e.data.replace(/.*if_height=(\d+)(?:&|$)/, "$1"));
        if (!isNaN(h) && h > 0) {
          iframe.height(h);
        }
      }
    }, false);
  }
}
