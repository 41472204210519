import jQuery from 'jquery';

export default function InitCard() {
  let initialHeight = 0;

  jQuery('.card__content-expandable .card__content-text').each(function () {
    jQuery(this).clone().attr('class', 'card__content-text card__content-full text margin-top--m').appendTo(jQuery(this).closest('.card__content-expandable').parent());
  });

  jQuery('.card__content-expandable .card__content-link').on('click', function () {
    let $element, $text;

    $element = jQuery(this);
    $text = $element.parent().find('.card__content-text').eq(0);

    initialHeight = $text.outerHeight();

    $text
      .css({
        // Set height to prevent instant jumpdown when max height is removed
        'height': 'auto',
        'max-height': 9999
      });

    $text.removeClass('text--fadeout');

    $element.hide();
    $element.parent().find('.card__content-backlink').css('display', 'inline-flex');

    return false;
  });

  jQuery('.card__content-expandable .card__content-backlink').on('click', function () {
    let $element, $text;

    $element = jQuery(this);
    $text = $element.parent().find('.card__content-text').eq(0);

    $text
      .css({
        height: initialHeight,
        'max-height': 9999
      });

    $text.addClass('text--fadeout');

    $element.hide();
    $element.parent().find('.card__content-link').css('display', 'inline-flex');

    return false;
  });

  jQuery('.flex-pos > .flex-pos__entry').each(function () {
    jQuery(this).clone().appendTo(jQuery('.flex-pos').parent());
  });
}
