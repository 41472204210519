export default function InitInputs() {
  jQuery('.textbox__input, .textarea__input').each(function () {
    const formGroup = jQuery(this).parent().parent()

    if (!!jQuery(this).val()) {
      formGroup.addClass('form-group--is-active')
    }

    jQuery(this).on('blur', function () {
      const input = jQuery(this).val()
      formGroup.removeClass('form-group--is-active')
      input === ''
        ? formGroup.removeClass('form-group--is-active')
        : formGroup.addClass('form-group--is-active')
    })
    jQuery(this).on('focus', function () {
      formGroup.addClass('form-group--is-active')
    })
  })

  jQuery('.textarea__input--autogrow').each(function (){
    jQuery(this).on('input', function (e) {
      const element = e.target
      element.style.height = 'auto'
      element.style.height = (element.scrollHeight) + 'px'
    })
  })
}
