import jQuery from 'jquery';
import Swiper from "swiper";
import {Navigation, Pagination} from "swiper/modules";

let newsListAjax = 0
let isLoading = false
let pageToLoad = 2
/**
 * @type {number}
 */
let numberOfPages

function GetNewsList(form, page) {
  let ajaxUrl = form.attr('action')
  let data = form.serializeArray()
  let container = 'news-container-' + form.data('container')

  if (page) {
    data.push({name: 'tx_news_pi1[currentPage]', value: page})
  }

  if (newsListAjax) {
    newsListAjax.abort()
    newsListAjax = 0
  }

  newsListAjax = jQuery.ajax({
    url: ajaxUrl,
    type: 'POST',
    data: data,
    success: function (result) {
      const newsForm = $(jQuery.parseHTML(result)).find('form.news-filter-form');
      numberOfPages = parseInt(newsForm.data('numberOfPages') ?? '')
      if (Number.isNaN(numberOfPages)) {
        numberOfPages = 99
      }
      let ajaxDom = jQuery(result).find('#' + container)
      jQuery('#' + container).empty().append(ajaxDom.html())
    }
  })
}

export default function InitNewsList() {

  const news = jQuery('.news')
  const newsForm = news.find('form.news-filter-form')
  const newsTrigger = news.find('.news-list__trigger')

  numberOfPages = parseInt(newsForm.data('numberOfPages') ?? '')
  if (Number.isNaN(numberOfPages)) {
    numberOfPages = 99
  }

  jQuery('.news .news-tools select').on('selectmenuchange', (e) => {
    let form = jQuery(e.currentTarget).parents('form')
    GetNewsList(form, 0)
  })

  jQuery('.news .news-tools input').on('keyup ', (e) => {
    let form = jQuery(e.currentTarget).parents('form')
    GetNewsList(form, 0)
  })

  // disable (enter) submit
  newsForm.on('submit ', (e) => {
    e.preventDefault()
  })

  jQuery('.news').on('click', '.page-navigation a', (e) => {
    e.preventDefault()
    let page = jQuery(e.currentTarget).data('page')
    let form = jQuery(e.currentTarget).parents('.news').find('form')
    GetNewsList(form, page)
  })

  /**
   * @type {IntersectionObserverCallback}
   */
  const loadMoreNewsEntries = (entries) => {
    // we always observe exactly one element
    if (!entries[0]?.isIntersecting ?? false) {
      return;
    }

    loadMoreNewsEntriesAjax(newsForm)
  }

  const loadMoreNewsEntriesAjax = (form) => {
    if (isLoading) {
      return
    }

    if (pageToLoad > numberOfPages) {
      return
    }

    isLoading = true
    newsTrigger.addClass('is-loading')

    let ajaxUrl = form.attr('action')
    let data = form.serializeArray()
    let container = 'news-container-' + form.data('container')

    data.push({name: 'tx_news_pi1[currentPage]', value: pageToLoad})

    if (newsListAjax) {
      newsListAjax.abort()
      newsListAjax = 0
    }

    newsListAjax = jQuery.ajax({
      url: ajaxUrl,
      type: 'POST',
      data: data,
      success: function (result) {
        let ajaxDom = jQuery(result).find('#' + container)
        const containerElement = jQuery(`#${container}`)
        containerElement.append(ajaxDom.html())
        const updatedNumberOfPages = parseInt(containerElement.find('.pagination-info').data('pagination'))
        if (Number.isNaN(updatedNumberOfPages) && updatedNumberOfPages > 0) {
          numberOfPages = updatedNumberOfPages
        }
        isLoading = false
        newsTrigger.removeClass('is-loading')
        pageToLoad++
      }
    })
  }

  // observe trigger
  const newsListTrigger = document.querySelector('.news-list__trigger')
  if (!!newsListTrigger) {
    const newsListObserver = new IntersectionObserver(loadMoreNewsEntries)
    newsListObserver.observe(newsListTrigger)
  }

  const newsListSliders = document.querySelectorAll('.news-list--slider')

  for (const newsListSlider of newsListSliders) {
    newsListSlider.querySelector('.swiper-slide')
    newsListSlider.querySelector('.swiper-wrapper')
    new Swiper(newsListSlider.querySelector('.swiper'), {
      modules: [Navigation],
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 30,
      navigation: {
        nextEl: newsListSlider.querySelector('.news-list__next'),
        prevEl: newsListSlider.querySelector('.news-list__prev'),
      },
      breakpoints: {
        // mobile
        767: {
          slidesPerView: 2, slidesPerGroup: 2,
        },
        // tablet
        1023: {
          slidesPerView: 3, slidesPerGroup: 3,
        },
      },
      loop: false,
    })
  }

}
