import Swiper from 'swiper'
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import { standortBreakpointsConfig } from './config/standortSliderConfig';

export default function InitSlider() {

  const sliderImageList = document.querySelectorAll('.slider--image-list');

  for (const sliderImage of sliderImageList) {
    const documentWidth = document.body.clientWidth
    // breakpoints didnt work here
    const imageListSlider = new Swiper(sliderImage.querySelector('.swiper'), {
      modules: [Navigation],
      slidesPerView: documentWidth < 1440
        ? documentWidth < 768 ? 1 : 3
        : 4,
      slidesPerGroup: documentWidth < 1440
        ? documentWidth < 768 ? 1 : 3
        : 4,
      spaceBetween: documentWidth < 1440
        ? documentWidth < 768 ? 0 : 30
        : 40,
      navigation: {
        nextEl: sliderImage.querySelector('.swiper-button-next'),
        prevEl: sliderImage.querySelector('.swiper-button-prev'),
      },
    })
  }

  const gallery = document.querySelectorAll('.gallery');

  for (const image of gallery) {
    let gallerySwiper = new Swiper(image.querySelector('.swiper'), {
      modules: [Navigation],
      navigation: {
        nextEl: image.querySelector('.swiper-button-next'),
        prevEl: image.querySelector('.swiper-button-prev'),
      },
    })

    const galleryOverviewElements = image.querySelectorAll('.gallery__overview-item')

    let i = 0;
    for (const galleryOverviewElement of galleryOverviewElements) {
      galleryOverviewElement.addEventListener('click', () => {
        gallerySwiper.slideTo(i);
      })
      i++;
    }
  }

  const heroSliderElements = document.querySelectorAll('.hero-slider')

  for (const heroSliderElement of heroSliderElements) {
    let heroSlider = new Swiper(heroSliderElement.querySelector('.swiper'), {
      modules: [Navigation, Pagination, Autoplay],
      pagination: {
        el: heroSliderElement.querySelector('.swiper-pagination'),
        clickable: true,
        paginationHide: false,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      autoplay: {
        delay: 5000,
      },
      loop: true,
    })

    heroSliderElement.addEventListener('mouseover', () => {
      if (heroSlider.autoplay.running) {
        heroSlider.autoplay.stop()
      }
    })

    heroSliderElement.addEventListener('mouseout', () => {
      if (heroSlider.autoplay.running) {
        heroSlider.autoplay.start()
      }
    })
  }

  const pageSliderElements = document.querySelectorAll('.page-slider');

  for (const pageSliderElement of pageSliderElements) {
    new Swiper(pageSliderElement.querySelector('.swiper'), {
      modules: [Pagination, Navigation],
      pagination: {
        el: pageSliderElement.querySelector('.swiper-pagination'),
        clickable: true,
        paginationHide: false,
      },
      navigation: {
        nextEl: pageSliderElement.querySelector('.swiper-button-next'),
        prevEl: pageSliderElement.querySelector('.swiper-button-prev'),
      },
      autoplay: false,
      loop: true,
    })
  }

  const newsSliderElements = document.querySelectorAll('.news-slider')

  for (const newsSliderElement of newsSliderElements) {
    new Swiper(newsSliderElement.querySelector('.swiper'), {
      modules: [Pagination, Navigation],
      pagination: {
        el: newsSliderElement.querySelector('.swiper-pagination'),
        clickable: true,
        paginationHide: false,
      },
      navigation: {
        nextEl: newsSliderElement.querySelector('.swiper-button-next'),
        prevEl: newsSliderElement.querySelector('.swiper-button-prev'),
      },
      autoplay: false,
      loop: true,
    })
  }

  const updateConfigOnOrientationChange = (fromPortraitMode) => standortBreakpointsConfig[+fromPortraitMode]

  const standortSliderElements = document.querySelectorAll('.standort-slider');

  for (const standortSliderElement of standortSliderElements) {
    const isLandscape = window.matchMedia('(orientation: landscape)').matches

    let standortSlider = new Swiper(standortSliderElement.querySelector('.swiper'), {
      modules: [Navigation],
      spaceBetween: 30,
      navigation: {
        nextEl: standortSliderElement.querySelector('.standort-slider__next'),
        prevEl: standortSliderElement.querySelector('.standort-slider__prev'),
      },
      breakpoints: standortBreakpointsConfig[+isLandscape],
      loop: false,
    })

    standortSlider.on('orientationchange', function () {
      standortSlider.params.breakpoints = updateConfigOnOrientationChange(
        window.matchMedia('(orientation: portrait)').matches
      )
      standortSlider.init()
    })

  }

  const gridMobileSliderElements = document.querySelectorAll('.grid--mobile-slider');

  for (const gridMobileSliderElement of gridMobileSliderElements) {
    new Swiper(gridMobileSliderElement.querySelector('.swiper'), {
      modules: [Pagination, Navigation],
      pagination: {
        el: gridMobileSliderElement.querySelector('.swiper-pagination'),
        clickable: true,
        paginationHide: true,
      },
      navigation: {
        nextEl: gridMobileSliderElement.querySelector('.swiper-button-next'),
        prevEl: gridMobileSliderElement.querySelector('.swiper-button-prev'),
      },
      autoplay: false,
      loop: true,
    })
  }

  const sliderTestimonialElements = document.querySelectorAll('.slider--testimonials')

  for (const sliderTestimonialElement of sliderTestimonialElements) {
    new Swiper(sliderTestimonialElement.querySelector('.swiper'), {
      modules: [Pagination, Navigation],
      pagination: {
        el: sliderTestimonialElement.querySelector('.swiper-pagination'),
        clickable: true,
        paginationHide: true,
      },
      navigation: {
        nextEl: sliderTestimonialElement.querySelector('.swiper-button-next'),
        prevEl: sliderTestimonialElement.querySelector('.swiper-button-prev'),
      },
      autoplay: false,
      loop: true,
    })
  }

  const publicationElements = document.querySelectorAll('.publications')

  for (const publicationElement of publicationElements) {
    new Swiper(publicationElement.querySelector('.swiper'), {
      modules: [Pagination, Navigation],
      pagination: {
        el: publicationElement.querySelector('.swiper-pagination'),
        clickable: true,
        paginationHide: true,
      },
      slidesPerView: 1,
      spaceBetween: 50,
      breakpoints: {
        // mobile
        767: {
          slidesPerView: 2,
        },
        // tablet
        1020: {
          slidesPerView: 3,
        },
      },
      navigation: {
        nextEl: publicationElement.querySelector('.publications__next'),
        prevEl: publicationElement.querySelector('.publications__prev'),
      },
      autoplay: false,
      loop: false,
      allowTouchMove: publicationElement.dataset['touch'],
    })
  }

  const cardSliderElements = document.querySelectorAll('.card--slider')

  for (const cardSliderElement of cardSliderElements) {
    let activeIndex = 0
    let previousIndex = 0

    let cardSwiper = new Swiper(cardSliderElement.querySelector('.swiper'), {
      modules: [Navigation],
      navigation: {
        nextEl: cardSliderElement.querySelector('.swiper-button-next'),
        prevEl: cardSliderElement.querySelector('.swiper-button-prev'),
      },
      on: {
        init: function () {
          cardSliderElement.querySelector('.card__content-slide').hidden = false
        },
        transitionStart: function () {

          if (cardSwiper != undefined && cardSliderElement.querySelectorAll('.card__content-slide').length > 0) {
            if (activeIndex !== cardSwiper.realIndex) {
              activeIndex = cardSwiper.realIndex
              previousIndex = cardSwiper.previousIndex - 1

              // TODO remove or rebuild
              // cardSliderElement.
              //
              // $element.find('.card__content-slide').eq(previousIndex).fadeOut(200, function () {
              //   $element.find('.card__content-slide').eq(activeIndex).fadeIn(200)
              // })
            }
          } else {
            cardSliderElement.querySelector('.card__content-slide').hidden = false
          }

        },
      },
      autoplay: false,
      loop: true,
    })
  }

  const reviewsSliderElements = document.querySelectorAll('.reviews__slider');

  for (const reviewsSliderElement of reviewsSliderElements) {
    new Swiper(reviewsSliderElement.querySelector('.swiper'), {
      modules: [Navigation],
      navigation: {
        nextEl: reviewsSliderElement.querySelector('.reviews__next'),
        prevEl: reviewsSliderElement.querySelector('.reviews__prev'),
      },
      autoplay: false,
      loop: true,
      spaceBetween: 30,
      slidesPerView: 1,
      allowTouchMove: true,
      breakpoints: {
        // mobile
        760: {
          slidesPerView: 2,
        },
      },
    })
  }
}
