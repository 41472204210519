/**
 * Image Comparison Slider Functionality
 * inspired by https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_image_compare
 */
export const InitImageComparison = () => {
  let slideActive: boolean = false;
  let comparisonWrapper: Element | null = null;
  let handler: Element | null = null;

  /**
   * Get cursor position relative to the comparison wrapper (in %)
   * @param e MouseEvent | TouchEvent
   */
  const getCursorPos = (e: MouseEvent | TouchEvent) => {
    const event = !!(e as TouchEvent).changedTouches
      ? (e as TouchEvent).changedTouches[0]
      : e;

    /**
     * Get the position information of the comparison wrapper
     */
    const wrapperPositionInfo: DOMRect | null = comparisonWrapper && comparisonWrapper.getBoundingClientRect();

    /**
     * Calculate the cursor's x coordinate, relative to the image
     * Consider any page scrolling
     */
    const relativePixelPosition = wrapperPositionInfo && event
      ? (event as MouseEvent).pageX - wrapperPositionInfo.left - window.scrollX
      : null;

    return wrapperPositionInfo && relativePixelPosition
      ? ((relativePixelPosition / wrapperPositionInfo?.width) * 100)
      : relativePixelPosition;
  };

  /**
   * Reads out the relative cursor position and sets the width and position of the image overlay / comparison handler
   * @param e MouseEvent | TouchEvent
   */
  const slideMove = (e: MouseEvent | TouchEvent) => {
    /**
     * If the slider is no longer clicked, exit this function
     */
    if (slideActive) {
      /**
       * Get the cursor's relative x position comared to comparison wrapper (%)
       */
      let relativeCursorPosition = getCursorPos(e);

      /**
       * Handling of positioning if the cursor breaches the min / max border of 0 / 100%
       */
      if (relativeCursorPosition && relativeCursorPosition <= 0) {
        relativeCursorPosition = 0;
      } else if (relativeCursorPosition && relativeCursorPosition > 100) {
        relativeCursorPosition = 100;
      }

      if (comparisonWrapper && relativeCursorPosition) {
        const overlayElement = comparisonWrapper.querySelector('.image-comparison__overlay');

        /**
         * Assign widths and left position
         */
        if (overlayElement) {
          (overlayElement as HTMLElement).style.width = `${ 100 - relativeCursorPosition }%`;
          (handler as HTMLElement).style.left = `${ relativeCursorPosition }%`;
        }
      }
    }
  };

  /**
   * Deactivate slider mode
   * @param e Event
   */
  const slideFinished = (e: Event) => {
    e.preventDefault();
    slideActive = false;
  };

  /**
   * Set the slider mode to active
   * Add fuirther event callbacks (mousemove, touchmove) in order to calculate the correct positions
   * @param e Event
   * @param passedHandler Element
   */
  const slideReady = (e: Event, passedHandler: Element) => {
    /**
     * Prevent any other actions that may occur when moving over the image
     */
    e.preventDefault();

    handler = passedHandler

    /**
     * The slider is now clicked and ready to move
     */
    slideActive = true;

    /**
     * Assign the wrapper element for further calculations
     */
    comparisonWrapper = handler && handler.closest('.image-comparison');

    /**
     * Execute a calculation function when the slider is moved
     */
    window.addEventListener('mousemove', (e) => slideMove(e));
    window.addEventListener('touchmove', (e) => slideMove(e));
  };

  /**
   * Add initial event callbacks starting / stopping the interaction with comparison slider
   */
  document.querySelectorAll('.image-comparison__handle')
    .forEach(handler => {
      handler.addEventListener('mousedown', (e) => slideReady(e, handler));
      handler.addEventListener('touchstart', (e) => slideReady(e, handler));
      window.addEventListener('mouseup', slideFinished);
      window.addEventListener('touchend', slideFinished);
    });
};
