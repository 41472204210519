import jQuery from 'jquery';
import DropdownTerminFooter from './dropdownTerminFooter'
import ConditionalDropdowns from './conditionalDropdowns'
import InitCollapsible from './collapsible'
import InitSlider from './slider'
import InitVideo from './video'
import InitButtons from './buttons'
import InitHeader from './header'
import { default as InitCalendar } from './calendar'
import InitTeaser from './teaser'
import InitCard from './cards'
import InitJobs from './jobs'
import InitMasonry from './masonry'
import InitInputs from './inputs'
import InitPersonList from './person-list/personList'
import InitIE11 from './ie'
import InitPushTeaser from './pushTeaser'
import InitTicker from './ticker'
import InitNewsList from './newsList'
import InitLocationList from './location-list/location-list-init'
import InitSearch from './search'
import InitGalleryGLightBox from './gallery'
import { InitImageComparison } from './image-comparison/image-comparison';
import {initializeSelectMenu} from "../../shared/ts/select-menu/select-menu-init";

const isIE = !!document.documentMode
const hasTicker = !!document.getElementById('ticker')
const hasInputs = !!document.querySelector('.textbox__input, .textarea__input')
const hasVideo = !!document.querySelector('.video')
const hasMasonry = !!document.querySelector('.masonry')
const hasNews = !!document.querySelector('.news')
const hasPushTeaser = !!document.querySelector('.push-teaser')
const hasSearch = !!document.querySelector('.block--solr_pi_search')

jQuery(function ($) {
  if (isIE) {
    InitIE11()
  }

  if (hasTicker) {
    InitTicker()
  }

  if (hasInputs) {
    InitInputs()
  }

  if (hasVideo) {
    InitVideo()
  }

  if (hasNews) {
    InitNewsList()
  }

  if (hasPushTeaser) {
    InitPushTeaser()
  }

  if (hasSearch) {
    InitSearch()
  }

  initializeSelectMenu()
  InitCollapsible()
  InitSlider()
  InitHeader()
  InitButtons()
  InitCalendar()
  InitTeaser()
  InitCard()
  InitJobs()
  InitPersonList()
  InitGalleryGLightBox()

  InitLocationList()
  InitImageComparison()

  let $dropdownHiddenInput = $('.fachgebietStandortMappingJsonUri')
  if ($dropdownHiddenInput.length && !!$dropdownHiddenInput.val()) {
    $.ajax({
      dataType: 'json',
      url: $dropdownHiddenInput.val(),
      success: function (data) {
        DropdownTerminFooter(data)
      },
    })
  }

  if ($('.dropdowns--conditional').length > 0) {
    $('.dropdowns--conditional').each(function () {
      ConditionalDropdowns($(this))
    })
  }

  if (window.AppointmentSlots) {
    $('.calendar').fullCalendar(
      'renderEvents',
      window.AppointmentSlots,
      true,
    )
  }

  if (hasMasonry) {
    InitMasonry()
  }

  const headerHeight = 150
  const mobileHeaderHeight = 50
  const hash = window.location.hash
  const target = $(hash)

  // set offset to anchor because of sticky nav
  if (target.length) {
    $('html,body').clearQueue().stop().animate({
      scrollTop: target.offset().top - headerHeight, //offsets for fixed header
    }, 'linear')
  }

  // scroll to anchor if on same page
  $('a[href^="#"]').on('click', function () {
    const href = $(this).attr('href')
    const target = $(href)

    // check if device is mobile
    if (window.matchMedia('only screen and (max-width: 760px)').matches && target.length) {
      $('html,body').animate({
        scrollTop: target.offset().top - mobileHeaderHeight,
      }, 'linear')
    } else if (target.length) {
      $('html,body').animate({
        scrollTop: target.offset().top - headerHeight,
      }, 'linear')
    }
  })
})
