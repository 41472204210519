import GLightBox from 'glightbox'
import {MOBILE_SCREEN_WIDTH} from './constants'

export default function InitGalleryGLightBox() {

  const lightbox = GLightBox({
    height: 'auto',
    width: 'auto',
  })

  function calcButtons(nextButton, prevButton, closeButton, currentImage) {
    const currentImageClientRect = currentImage.getBoundingClientRect()

    // calc: (position x of image + width of image) - screen width + 30
    const positionRight = Math.abs((currentImageClientRect.x + currentImageClientRect.width) - window.innerWidth - 30) + 'px'

    nextButton.style.right = positionRight
    nextButton.style.top = '45%'
    prevButton.style.left = (currentImageClientRect.x + 30) + 'px'
    prevButton.style.top = '45%'
    closeButton.style.right = positionRight
    closeButton.style.top = (currentImageClientRect.y + 30) + 'px'
  }

  function calcButtonsMobile(nextButton, prevButton, closeButton, currentSlideDescription) {
    const slideDescriptionRect = currentSlideDescription.getBoundingClientRect()

    const positionX = (slideDescriptionRect.x + 65) + 'px'
    const positionTop = (slideDescriptionRect.y - 15) + 'px'

    prevButton.style.left = positionX
    prevButton.style.top = positionTop
    nextButton.style.right = positionX
    nextButton.style.top = positionTop
    closeButton.style.right = '10px'
    closeButton.style.top = '15px'
  }

  function onEvent() {
    const nextButton = document.querySelector('.gnext.gbtn')
    const prevButton = document.querySelector('.gprev.gbtn')
    const closeButton = document.querySelector('.gclose.gbtn')
    const currentSlide = document.querySelector('.gslide.current')

    if (!nextButton || !prevButton || !closeButton || !currentSlide) {
      return
    }

    const currentImage = currentSlide.querySelector('img')
    const currentSlideDescription = currentSlide.querySelector('.gslide-desc')

    if (!currentImage || !currentSlideDescription) {
      return
    }

    const isMobile = window.matchMedia(`only screen and (max-width: ${MOBILE_SCREEN_WIDTH}px)`).matches

    if (!isMobile) {
      calcButtons(nextButton, prevButton, closeButton, currentImage)
    } else {
      calcButtonsMobile(nextButton, prevButton, closeButton, currentSlideDescription)
    }
  }

  // also triggers on open
  lightbox.on('slide_changed', onEvent)
  window.addEventListener('resize', onEvent)
}
