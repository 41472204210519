import $ from 'jquery'
import Masonry from 'masonry-layout'
import ImagesLoaded from 'imagesloaded'

export const REQUEST_MASONRY_LAYOUT_UPDATE_EVENT = 'request-masonry-layout-update'

export default function InitMasonry() {
  const masonryElements = document.querySelectorAll(".masonry")

  for (const masonryElement of masonryElements) {
    const masonryGrid = new Masonry(masonryElement, {
      itemSelector: '.masonry-item',
      columnWidth: '.masonry-item:not([style*="display: none"]):not([hidden])',
      gutter: 5,
      percentPosition: true,
    })

    new ImagesLoaded(masonryGrid, () => {
      masonryGrid.layout()
    })

    window.addEventListener("load", () => {
      masonryGrid.layout()
    })

    const images = document.querySelectorAll("picture img")

    for (const image of images) {
      image.addEventListener("load", () => {
        masonryGrid.layout()
      })
    }

    document.addEventListener(REQUEST_MASONRY_LAYOUT_UPDATE_EVENT, () => {
      masonryGrid.layout()
    })
  }
}
