import { notUndefined } from '../filter';
import { REQUEST_MASONRY_LAYOUT_UPDATE_EVENT } from '../masonry';

interface SwiperElementBind {
  swiper: {
    update(): void;
  };
}

/**
 * Filter (show / hide) the location dom elements by their specializations.
 *
 * There can be multiple "family members" for a single root location:
 * the parent itself and up to n children.
 * We only show at most one family member and we prefer children over parents.
 */
const filterLocationDomElementsBySpecialization = (
  parent: HTMLElement,
  selectedSpecializations: number[]
) => {
  // initially hide all elements
  const flatDomListElements = findLocationDomElements(parent);
  flatDomListElements.forEach((element) => (element.hidden = true));

  const selectedParentLocations = new Map<number, HTMLElement>();
  const selectedChildLocations = new Map<number, HTMLElement>();

  // fill out the selected element maps
  for (const locationDomElement of flatDomListElements) {
    const locationNode = collectLocationNode(locationDomElement);
    if (locationNode.error) {
      console.warn(
        `Could not collect location element: ${locationNode.error.description}`,
        locationNode.element
      );
      continue;
    }

    if (!locationNode.isSelected(selectedSpecializations)) {
      continue;
    }

    setWithoutOverride(
      locationNode.isParent ? selectedParentLocations : selectedChildLocations,
      locationNode.parentUid,
      locationNode.element
    );
  }

  // reveal the selected child locations and discard their parents
  for (const [parentId, element] of selectedChildLocations.entries()) {
    selectedParentLocations.delete(parentId);
    element.hidden = false;
  }

  // reveal the remaining parent locations that do not have any selected children
  for (const element of selectedParentLocations.values()) {
    element.hidden = false;
  }
};

/**
 * Reveals all parents and hides all children.
 * This is the "filtering" method used, when no specialization is selected.
 */
const filterLocationDomElementsByParentStatus = (parent: HTMLElement) =>
  findLocationDomElements(parent).forEach((locationElement) => {
    locationElement.hidden = !fetchLocationIdentifier(locationElement).isParent;
  });

/**
 * Sets a new entry in the map only if the map has no entry for this key.
 */
const setWithoutOverride = <K, V>(map: Map<K, V>, key: K, value: V) => {
  if (map.has(key)) {
    return;
  }
  map.set(key, value);
};

/**
 * The specialization is missing on the dom element datamap.
 */
const ERROR_SPECIALIZATION_MISSING = Symbol('specializations missing');

const collectLocationNode = (element: HTMLElement) => {
  const specializations = element.dataset['fachgebiete']
    ?.split(',')
    .map((recordIdString) => parseInt(recordIdString));

  if (!specializations) {
    return { error: ERROR_SPECIALIZATION_MISSING, element } as const;
  }

  const { isParent, parentUid, error } = fetchLocationIdentifier(element);

  if (typeof error === 'symbol') {
    return { error: error, element } as const;
  }

  return {
    isParent,
    parentUid,
    element,
    isSelected: (selectedSpecializationIds: number[]) => {
      return specializations.some((ownSpecializationId) =>
        selectedSpecializationIds.includes(ownSpecializationId)
      );
    },
  } as const;
};

/**
 * The location is missing on datamap of the dom element.
 *
 * Properly a null pointer on the backend.
 */
const ERROR_LOCATION_ATTRIBUTE_NOT_PRESENT = Symbol(
  'location attribute is not present'
);
/**
 * There was a location identifier on the dom element's datamap,
 * but it is of unknown type.
 *
 * Backend / Frontend version mismatch?
 */
const ERROR_UNRECOGNIZABLE_IDENTIFIER_FORMAT = Symbol(
  'unrecognizable location identifier given'
);

/**
 * Reads the location identifier of a {@link HTMLElement DOM location element},
 * which is either a record id for parent elements or
 * a parent record id prefixed with "parent-" for children,
 * which links to the parent.
 */
const fetchLocationIdentifier = (element: HTMLElement) => {
  const locationData = element.dataset['standort'];

  if (!locationData) {
    return { error: ERROR_LOCATION_ATTRIBUTE_NOT_PRESENT } as const;
  }

  const parentIdentifierMatcher = /^\d+$/;
  if (parentIdentifierMatcher.test(locationData)) {
    return { isParent: true, parentUid: parseInt(locationData) } as const;
  }

  const childIdentifierMatcher = /^parent-\d+$/;
  if (childIdentifierMatcher.test(locationData)) {
    return {
      isParent: false,
      parentUid: parseInt(locationData.substring(7)),
    } as const;
  }

  return { error: ERROR_UNRECOGNIZABLE_IDENTIFIER_FORMAT } as const;
};

const jumpIfPossible = (select: HTMLSelectElement) => {
  [...select.selectedOptions]
    .map((option) => option.dataset['uri'])
    .filter(notUndefined)
    .filter((uri) => uri !== '')
    .forEach((uri) => {
      window.location.assign(uri);
    });
};

const findLocationDomElements = (parent: HTMLElement) =>
  parent.querySelectorAll<HTMLElement>('[data-fachgebiete]');

/**
 * Performs the actual filtering on the dom.
 */
const filterLocations = (
  parent: HTMLElement,
  selectElement: HTMLSelectElement
) => {
  const selectedIds = [...selectElement.selectedOptions]
    .map((option) => parseInt(option.value))
    .filter(
      (selectedRecordId) =>
        !Number.isNaN(selectedRecordId) && selectedRecordId > 0
    );

  if (selectedIds.length) {
    filterLocationDomElementsBySpecialization(parent, selectedIds);
  } else {
    filterLocationDomElementsByParentStatus(parent);
  }
  updateLayout(parent);
};

/**
 * Updates the swiper / masonry layout after some dom manipulation
 */
const updateLayout = (parent: HTMLElement) => {
  document.dispatchEvent(new Event(REQUEST_MASONRY_LAYOUT_UPDATE_EVENT));
  [...parent.querySelectorAll<HTMLElement>('.swiper')]
    .filter(
      (
        element: HTMLElement | (HTMLElement & SwiperElementBind)
      ): element is HTMLElement & SwiperElementBind => 'swiper' in element
    )
    .map((swiperElement) => swiperElement.swiper.update());
};

const createSelectHandler = (parent: HTMLElement) => (e: Event) => {
  const selectElement = e.target;

  if (!(selectElement instanceof HTMLSelectElement)) {
    return;
  }

  jumpIfPossible(selectElement);
  filterLocations(parent, selectElement);
};

const initializeLocationList = (
  parent: HTMLElement,
  registerListener: (
    callback: (e: Event) => void,
    selectElement: HTMLSelectElement
  ) => void
) => {
  const select = parent.querySelector<HTMLSelectElement>(
    'select[name="standort-filter-department"]'
  );
  if (!select) {
    return;
  }

  filterLocations(parent, select);

  registerListener(createSelectHandler(parent), select);
};

export const initializeAllLocationLists = (
  registerListener: (
    callback: (e: Event) => void,
    selectElement: HTMLSelectElement
  ) => void
) => {
  document
    .querySelectorAll<HTMLElement>('.block--locationlist')
    .forEach((containerElement) =>
      initializeLocationList(containerElement, registerListener)
    );
};
