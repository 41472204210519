export const hoverIntent = (el, onOver, onOut, options = {}) => {
  let x;
  let y;
  let pX;
  let pY;
  const h = {};
  let state = 0;
  let timer = 0;

  let optionsWithDefaults = {
    sensitivity: options.sensitivity ?? 7,
    interval: options.interval ?? 100,
    timeout: options.timeout ?? 200,
    overClass: options.overClass ?? ''
  };

  const delay = e => {
    if (timer) timer = clearTimeout(timer);
    state = 0;
    if (onOut) {
      return onOut.call(el, e);
    }
    el.classList.remove(optionsWithDefaults.overClass);
    return false;
  };

  const tracker = e => {
    x = e.clientX;
    y = e.clientY;
  };

  const compare = e => {
    if (timer) timer = clearTimeout(timer);
    if (Math.abs(pX - x) + Math.abs(pY - y) < optionsWithDefaults.sensitivity) {
      state = 1;
      if (onOver) {
        return onOver.call(el, e);
      }
      el.classList.add(optionsWithDefaults.overClass);
      return false;
    }
    pX = x;
    pY = y;
    timer = setTimeout(() => {
      compare(e);
    }, optionsWithDefaults.interval);
    return false;
  };

  // Public methods

  const dispatchOver = e => {
    if (timer) timer = clearTimeout(timer);
    el.removeEventListener('mousemove', tracker, false);

    if (state !== 1) {
      pX = e.clientX;
      pY = e.clientY;

      el.addEventListener('mousemove', tracker, false);

      timer = setTimeout(() => {
        compare(e);
      }, optionsWithDefaults.interval);
    }

    return this;
  };

  const dispatchOut = e => {
    if (timer) timer = clearTimeout(timer);
    el.removeEventListener('mousemove', tracker, false);

    if (state === 1) {
      timer = setTimeout(() => {
        delay(e);
      }, optionsWithDefaults.timeout);
    }

    return this;
  };

  if (el) {
    el.addEventListener('mouseover', dispatchOver, false);
    el.addEventListener('mouseout', dispatchOut, false);
  }

  h.options = opt => {
    optionsWithDefaults = { ...optionsWithDefaults, ...opt };
    return h;
  };

  h.remove = () => {
    if (!el) return;
    el.removeEventListener('mouseover', dispatchOver, false);
    el.removeEventListener('mouseout', dispatchOut, false);
  };

  return h;
};

const menuEl = document.querySelector('.menu');
hoverIntent(menuEl);
