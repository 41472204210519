import objectFitImages from 'object-fit-images'
import 'picturefill'
import 'intersection-observer'
import jQuery from 'jquery';

/*
 * Scripts containing overall fixes regarding the support of the Internet Explorer browser
 */
export default function InitIE11() {
  objectFitImages()

  jQuery('picture img.lazy').each(function () {
    if (jQuery(this).data('srcset')) {
      /*
       * SMSResponsiveImages combined with LazyLoading don't have srcset attribute loaded initially (picturefill req)
       * That's why it is important to set the needed attribute for the picturefill to be able to reevalute
       * and set the needed values in order to display the responsive image on IE11
       */
      if (!jQuery(this).attr('srcset')) {
        jQuery(this).attr('srcset', jQuery(this).data('srcset'))
      }
      /*
       * http://scottjehl.github.io/picturefill/
       * Reevaluate: If you dynamically change the srcset, sizes attributes, or
       * modify source elements, please use this reevaluate option and provide an
       * array or array-like object of your mutated img elements.
       */
      let timestamp = new Date().getTime()
      jQuery(this).attr('id', timestamp)
      picturefill({
        reevaluate: true,
        elements: [document.getElementById(timestamp.toString())],
      })
    }
  })
}
