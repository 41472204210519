import jQuery from 'jquery';

export default function InitButtons() {
  jQuery('.link--uptotop').on("click", function () {
    jQuery('html, body').animate({scrollTop: 0}, 300);
  });
  jQuery('button.uptotop').on("click", function () {
    jQuery('html, body').animate({scrollTop: 0}, 300);
  });
}
