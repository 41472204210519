import jQuery from 'jquery';

export default function InitTicker() {

  document.body.classList.add('has-ticker')

  const ticker = jQuery('.ticker')
  const tickerContent = jQuery('.ticker__content')
  const widthContainer = ticker.width()
  const widthText = tickerContent.width()

  tickerContent.css('left', 0)

  // if text is smaller than container make copies so whole container is filled
  if (widthText <= widthContainer) {
    const difference = Math.floor(widthContainer / widthText)
    const tickerElement = tickerContent.find('li:first')
    for (let i = 0; i < difference * 2; i++) {
      tickerElement.clone().appendTo(tickerContent)
    }
  }

  // loop function
  const tick = () => {
    const firstItem = tickerContent.find('li:first')
    const widthWithMargin = (firstItem.width() + parseInt(firstItem.css('marginRight')))
    const left = parseInt(tickerContent.css('left'))
    const speed = (Math.abs((widthWithMargin + left)) / 300) * 10000

    tickerContent.animate({
      left: "-" + widthWithMargin,
    }, speed, 'linear', function () {
      firstItem.clone(true).appendTo(tickerContent)
      firstItem.remove()
      tickerContent.css('left', 0)
      tick()
    });
  }

  // pause and resume ticker on hover
  ticker.on('mouseenter', function () {
    tickerContent.stop()
  })
  ticker.on('mouseleave', function () {
    tick()
  })

  // start ticker
  tick()
}
