import jQuery from 'jquery';
import 'selectize';
import {isMobile} from "./globalVariables";

function isInValues(needle, haystack) {
  let isIn = false;
  jQuery(haystack).each(function (idx, value) {
    if (needle === value) {
      isIn = true;
      return false;
    }
  });

  return isIn;
}

function fillDropdown(dropdown, options, value, isMobile, dropdownChanged) {

  if (!isMobile) {
    if (dropdown[0].selectize !== undefined) {
      dropdown[0].selectize.clearCache('option');
      dropdown[0].selectize.clearOptions();
      dropdown[0].selectize.addOption(options);
      dropdown[0].selectize.refreshOptions(false);
      if (!!value) {
        dropdown[0].selectize.setValue(value, true);
      }
    }
  } else {
    let placeholder = dropdown.attr('placeholder');
    dropdown.empty();

    if (dropdownChanged != true && placeholder !== undefined) {
      dropdown.append('<option hidden>' + placeholder + '</option>');
    }

    options.forEach(function (e) {
      let selected = false;
      if (e.value == value) {
        selected = true;
      }
      dropdown.append(jQuery('<option>', {value: e.value, text: e.name, selected: selected}));
    });
  }
}

export default function DropdownTerminFooter(terminFooterMapping) {

  if (jQuery('.form').length == 0) {
    return;
  }
  if (typeof terminFooterMapping === 'undefined') {
    return;
  }

  /**
   * GLOBAL VARIABLES
   */
  let fachgebietSelect = jQuery('.form .dropdown--expertise select');
  let standortSelect = jQuery('.form .dropdown--location select');
  let defaultFachgebiet = fachgebietSelect.data('defaultvalue');
  let defaultStandort = standortSelect.data('defaultvalue');
  let fachgebietChanged = false;
  let standortChanged = false;
  let chosenStandort = 0;
  let chosenFachgebiet = 0;

  /**
   * ON CHANGE HANDLERS
   */
  if (fachgebietSelect[0].selectize !== undefined) {
    fachgebietSelect[0].selectize.on('change', function (value) {
      fillDropdown(fachgebietSelect, terminFooterMapping['fachgebiete'], defaultFachgebiet);
      if (value) {
        let mapping = terminFooterMapping['mapping']['fachgebiete'][value];
        let tmpOptions = [];
        jQuery(terminFooterMapping['standorte']).each(function (idx, option) {
          if (isInValues(option.value, mapping)) {
            tmpOptions.push(option);
          }
        });

        fillDropdown(standortSelect, tmpOptions, defaultStandort);
      } else {
        fillDropdown(standortSelect, terminFooterMapping['standorte'], defaultStandort);
      }
    });
  }
  // mobile on change handlers (selectize deactivated)
  else {
    fachgebietSelect.on('change', function () {
      fachgebietChanged = true;
      chosenFachgebiet = jQuery(this).val();

      if (chosenFachgebiet !== 0) {
        let mapping = terminFooterMapping['mapping']['fachgebiete'][chosenFachgebiet];
        let tmpOptions = [];
        jQuery(terminFooterMapping['standorte']).each(function (idx, option) {
          if (isInValues(option.value, mapping)) {
            tmpOptions.push(option);
          }
        });
        fillDropdown(fachgebietSelect, terminFooterMapping['fachgebiete'], chosenFachgebiet, true, fachgebietChanged);
        fillDropdown(standortSelect, tmpOptions, (chosenStandort !== 0 ? chosenStandort : defaultStandort), true, standortChanged);
      } else {
        fillDropdown(standortSelect, terminFooterMapping['standorte'], defaultStandort, true, standortChanged);
      }
    });
  }

  if (standortSelect[0].selectize !== undefined) {
    standortSelect[0].selectize.on('change', function (value) {
      fillDropdown(standortSelect, terminFooterMapping['standorte'], defaultStandort);
      if (value) {
        let mapping = terminFooterMapping['mapping']['standorte'][value];
        let tmpOptions = [];
        jQuery(terminFooterMapping['fachgebiete']).each(function (idx, option) {
          if (isInValues(option.value, mapping)) {
            tmpOptions.push(option);
          }
        });
        fillDropdown(fachgebietSelect, tmpOptions, defaultFachgebiet);
      } else {
        fillDropdown(fachgebietSelect, terminFooterMapping['fachgebiete'], defaultFachgebiet);
      }
    });
  } else {
    standortSelect.on('change', function () {
      chosenStandort = jQuery(this).val();
      standortChanged = true;

      if (chosenStandort !== 0) {
        let mapping = terminFooterMapping['mapping']['standorte'][chosenStandort];
        let tmpOptions = [];
        jQuery(terminFooterMapping['fachgebiete']).each(function (idx, option) {
          if (isInValues(option.value, mapping)) {
            tmpOptions.push(option);
          }
        });

        fillDropdown(standortSelect, terminFooterMapping['standorte'], chosenStandort, true, standortChanged);
        fillDropdown(fachgebietSelect, tmpOptions, (chosenFachgebiet !== 0 ? chosenFachgebiet : defaultFachgebiet), true, fachgebietChanged);
      } else {
        fillDropdown(fachgebietSelect, terminFooterMapping['fachgebiete'], defaultFachgebiet, true, fachgebietChanged);
      }
    });
  }

  /**
   * INIT
   */
  // set chosen values as default variables
  if (fachgebietSelect.val() != '' && !isNaN(parseInt(fachgebietSelect.val()))) {
    defaultFachgebiet = fachgebietSelect.val();
  }
  if (standortSelect.val() != '' && !isNaN(parseInt(standortSelect.val()))) {
    defaultStandort = standortSelect.val();
  }

  // fill dropdowns
  fillDropdown(fachgebietSelect, terminFooterMapping['fachgebiete'], defaultFachgebiet, isMobile, fachgebietChanged);
  fillDropdown(standortSelect, terminFooterMapping['standorte'], defaultStandort, isMobile, fachgebietChanged);

  // if select values are already set - fire change handlers to adjust the dropdown of another select
  if (defaultStandort != '' && !isNaN(defaultStandort)) {
    if (standortSelect[0].selectize !== undefined) {
      standortSelect[0].selectize.setValue(defaultStandort, false);
    } else {
      standortSelect.val(defaultStandort).trigger('change');
    }
  } else if (defaultFachgebiet != '' && !isNaN(defaultFachgebiet)) {
    if (fachgebietSelect[0].selectize !== undefined) {
      fachgebietSelect[0].selectize.setValue(defaultFachgebiet, false);
    } else {
      standortSelect.val(defaultFachgebiet).trigger('change');
    }
  }

  // set defaults after init to undefined in order for on change handlers to work correctly
  defaultFachgebiet = undefined;
  defaultStandort = undefined;
}
