import jQuery from 'jquery';
import 'fullcalendar';

export default function InitCalendar() {
  jQuery('.calendar').each(function () {
    jQuery(this).fullCalendar({
      selectable: true,
      hiddenDays: [0],
      firstDay: 1,
      fixedWeekCount: false,
      header: {
        left: 'title',
        center: '',
        right: 'prev,next'
      },
      windowResizeDelay: 0,
      height: 'auto',
      aspectRatio: 1,
      locale: 'de'
    });
  });
}

/**
 * Exposes the fullCalendar object associated with an dom element.
 * Meant to be bound to the global object (window) for usage in embedded, non-bundled HTML script tags.
 *
 * @param {string} selector Selector for the calendar dom element
 * @param args Other arguments
 *
 * @return {object} The calendar object
 */
export function exposeCalendar(selector, ...args) {
  return jQuery(selector).fullCalendar(...args);
}
